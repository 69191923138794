import "./App.css";
import logo from "./assets/images/g28.png";

function App() {
  return (
    <div className="App">
      <div className="logo-wrapper">
        <img src={logo} alt="pedal reaction" />
      </div>
    </div>
  );
}

export default App;
